import { template as template_f8371eca20a2453789cdfb2b1b6b0e47 } from "@ember/template-compiler";
const FKLabel = template_f8371eca20a2453789cdfb2b1b6b0e47(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
