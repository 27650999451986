import { template as template_e0427c539d4146369890663c63bd7f7e } from "@ember/template-compiler";
import { fn } from "@ember/helper";
import { or } from "truth-helpers";
import DButton from "discourse/components/d-button";
const ShareSource = template_e0427c539d4146369890663c63bd7f7e(`
  <DButton
    @action={{fn @action @source}}
    @translatedTitle={{@source.title}}
    @icon={{or @source.icon @source.htmlIcon}}
    class="btn-default share-{{@source.id}}"
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ShareSource;
