import { template as template_02dec8f0e9d94f8db0cd5cb82dfff8d2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_02dec8f0e9d94f8db0cd5cb82dfff8d2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
