import { template as template_a029086af5ed492ba79c11e376c3b933 } from "@ember/template-compiler";
const FKText = template_a029086af5ed492ba79c11e376c3b933(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
