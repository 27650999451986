import { template as template_0b3c38b8f3204e5a81f8d8c053400e21 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0b3c38b8f3204e5a81f8d8c053400e21(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
