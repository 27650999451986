import { template as template_604510f77a754cf98363a749e554a533 } from "@ember/template-compiler";
const FKControlMenuContainer = template_604510f77a754cf98363a749e554a533(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
